/* Add this to the top of your index.css file */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ensure smooth scrolling across the site */
html {
  scroll-behavior: smooth;
}

/* Base styles */
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden; /* Prevent horizontal overflow on the body */
}

/* Dark mode styles */
.dark {
  background-color: #333;
  color: #141313;
}

.dark header,
.dark footer {
  background-color: #222;
}

/* Style for the image slider */
.image-slider img {
  width: 100%;
  height: auto;
}

/* Responsive utilities */
@media (max-width: 768px) {
  .image-slider img {
    height: auto;
  }
}

@media (max-width: 640px) {
  .flex-wrap .flex {
    flex-direction: column;
    align-items: start;
  }
}
